.hero {
  > .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-height: calc(100vh - 237px);
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 1.75rem;
    line-height: 1.1;
    text-align: center;

    > span {
      font-size: 1rem;
      color: $color-primary-lighter;
    }
  }

  .hero-image {
    align-self: center;
    margin-top: 2rem;
    > img {
      display: block;
      width: 100%;
      max-width: 375px;
      height: auto;
    }
  }

  .hero-scroll-icon {
    align-self: center;
    margin-top: auto;
    width:44px;
    height:32px;
    animation: up-down 1s infinite alternate;

    > img {
      display: block;
      width: 44px;
      height: 32px;
    }
  }

  @media (min-width: $breakpoint-sm) {
    > .container {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    h1 {
      font-size: 3rem;
      > span {
        font-size: 1.5rem;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    > .container {
      padding-top: 5rem;
      padding-bottom: 5rem;
      min-height: calc(100vh - 134px);
    }
  }

  @media (min-width: $breakpoint-lg) {
    h1 {
      max-width: 38rem;
      font-size: 3.5rem;
      text-shadow: .0625em .0625em $bg-color-base;
      text-align: left;

      > span {
        font-size: 1.75rem;
        text-shadow: .125em .125em $bg-color-base;
      }
    }

    .hero-image {
      display: none;
    }

  }

  @media (min-width: $breakpoint-xl) {
    > .container {
      padding-top: 12rem;
      padding-bottom: 4rem;
    }
  }
}


