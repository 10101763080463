::selection {
  color: #fff;
  background: $color-primary;
}

.home {
  @media (min-width: $breakpoint-lg) {
    background-image: url("/img/bg.png");
    background-image: url("/img/bg_lg.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
    background-attachment: scroll;
  }
  @media (min-width: $breakpoint-xxl) {
    background-image: url("/img/bg_xxl.png");
  }
}

// General layout

// Full width outer section
.section {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    min-height: 100vh;

    @media (min-width: $breakpoint-sm) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    @media (min-width: $breakpoint-md) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    @media (min-width: $breakpoint-lg) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

  }

  &-portfolio {
    background-color: #060a23;
  }
}


// Centered max width container
.container {
  width: 100%;
  max-width: 79rem; //1264px
  margin: 0 auto;
  padding: 0 1.5rem;

  @media (min-width: $breakpoint-md) {
    padding: 0 2.5rem;
  }
}

.contact-image {
  align-self: center;

  > img {
    display: block;
    width: 100%;
    max-width: 640px;
    height: auto;
  }
}

.social-link {
  display: block;
  transition: color .2s ease-in-out;

  > img {
    transition: filter .2s ease-in-out;
    width: 150px;
    height: 150px;
  }
  > span {
    display: block;
    margin-top: 1rem;
    text-align: center;
  }
  & + & {
    margin: 2rem 0 0 0;
  }

  &:hover,
  &:focus {
    > img {
      filter: brightness(1.5);
    }
  }

  @media (min-width: $breakpoint-sm) {
    display: inline-block;

    & + & {
      margin: 0 0 0 2rem;
    }
  }

}

// Text intro for sections
.intro {
  text-align: center;
  max-width: 40rem;

  > * + * {
    margin-top: 1rem;
  }
  & + * {
    margin-top: 3rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  p {
    color: $color-primary-lighter;
    color: #9fbd98;
  }

  @media (min-width: $breakpoint-sm) {
    & + * {
      margin-top: 4rem;
    }
    h2 {
      font-size: 2rem;
    }
  }

  @media (min-width: $breakpoint-md) {
    & + * {
      margin-top: 5rem;
    }
  }

  @media (min-width: $breakpoint-lg) {
    & + * {
      margin-top: 6rem;
    }
  }
}

.grid-portfolio {

  > * + * {
    margin-top: 1rem;
  }

  figure {
    display: block;
  }
  figcaption {
    display: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    box-shadow: 0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.12);
  }

  @media (min-width: $breakpoint-sm) {
    display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    > * + * {
      margin-top: 0;
    }
  }

  @media (min-width: $breakpoint-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

// Stack content, add top margins

// .stack {
//   --space: 1.5rem;
//   > * + * {
//     margin-top: var(--space);
//     margin-top: 1.5rem;
//   }
// }

// .stack-exception,
// .stack-exception + * {
//   --space: 8rem;
// }
