html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:root {
  --bg-color-base: #010416;
  --font-color-base: #fff;
  --space: 1.5rem;
  font-size: 1rem;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: var(--bg-color-base);
  font-family: "Press Start 2P", "Roboto", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.6;
  color: var(--font-color-base);
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
}

h1 {
  font-size: 2.0275rem;
}

h2 {
  font-size: 1.801875rem;
}

h3 {
  font-size: 1.601875rem;
}

h4 {
  font-size: 1.42375rem;
}

h5 {
  font-size: 1.265625rem;
}

h6 {
  font-size: 1.125rem;
}

address {
  margin: 0;
  font-style: normal;
  line-height: inherit;
}

p {
  margin: 0;
}

hr {
  margin: 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

pre {
  display: block;
  margin: 0;
  overflow: auto;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.875em;
  tab-size: 2;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

blockquote {
  margin: 0;
}

ol,
ul {
  padding-left: 2rem;
}

ol[class],
ul[class] {
  padding: 0;
}

ol,
ul,
dl {
  margin: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0;
}

figure,
figcaption {
  margin: 0;
}

figcaption {
  padding: 0.5rem 0 0 0;
  font-size: 0.875rem;
}

main {
  display: block;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #d9d9d9;
  text-decoration: none;
}

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

code {
  font-size: 0.875em;
  color: #8b2a65;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #010416;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
}

th {
  text-align: inherit;
}

th,
td {
  border: 1px solid #000;
  padding: .5rem;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #777;
  text-align: left;
  caption-side: bottom;
}

label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

::selection {
  color: #fff;
  background: #8b2a65;
}

@media (min-width: 992px) {
  .home {
    background-image: url("/img/bg.png");
    background-image: url("/img/bg_lg.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
    background-attachment: scroll;
  }
}

@media (min-width: 1600px) {
  .home {
    background-image: url("/img/bg_xxl.png");
  }
}

.section > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 100vh;
}

@media (min-width: 576px) {
  .section > .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 768px) {
  .section > .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .section > .container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.section-portfolio {
  background-color: #060a23;
}

.container {
  width: 100%;
  max-width: 79rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

@media (min-width: 768px) {
  .container {
    padding: 0 2.5rem;
  }
}

.contact-image {
  align-self: center;
}

.contact-image > img {
  display: block;
  width: 100%;
  max-width: 640px;
  height: auto;
}

.social-link {
  display: block;
  transition: color .2s ease-in-out;
}

.social-link > img {
  transition: filter .2s ease-in-out;
  width: 150px;
  height: 150px;
}

.social-link > span {
  display: block;
  margin-top: 1rem;
  text-align: center;
}

.social-link + .social-link {
  margin: 2rem 0 0 0;
}

.social-link:hover > img, .social-link:focus > img {
  filter: brightness(1.5);
}

@media (min-width: 576px) {
  .social-link {
    display: inline-block;
  }
  .social-link + .social-link {
    margin: 0 0 0 2rem;
  }
}

.intro {
  text-align: center;
  max-width: 40rem;
}

.intro > * + * {
  margin-top: 1rem;
}

.intro + * {
  margin-top: 3rem;
}

.intro h2 {
  font-size: 1.75rem;
}

.intro p {
  color: #a43c7b;
  color: #9fbd98;
}

@media (min-width: 576px) {
  .intro + * {
    margin-top: 4rem;
  }
  .intro h2 {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .intro + * {
    margin-top: 5rem;
  }
}

@media (min-width: 992px) {
  .intro + * {
    margin-top: 6rem;
  }
}

.grid-portfolio > * + * {
  margin-top: 1rem;
}

.grid-portfolio figure {
  display: block;
}

.grid-portfolio figcaption {
  display: none;
}

.grid-portfolio img {
  display: block;
  max-width: 100%;
  height: auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.2), 0 15px 12px rgba(0, 0, 0, 0.12);
}

@media (min-width: 576px) {
  .grid-portfolio {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
  .grid-portfolio > * + * {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .grid-portfolio {
    grid-template-columns: repeat(3, 1fr);
  }
}

.site-header > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 120rem;
}

@media (min-width: 768px) {
  .site-header > .container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.site-brand {
  margin-bottom: 2rem;
}

.site-brand > a {
  display: flex;
  align-items: center;
}

.site-brand > a > span {
  padding-left: 1rem;
}

.site-brand img {
  width: 45px;
}

@media (min-width: 768px) {
  .site-brand {
    margin-bottom: 0;
    padding-right: 1.25rem;
  }
  .site-brand > a > span {
    padding-left: 1.25rem;
    font-size: 1.25rem;
  }
  .site-brand img {
    width: 90px;
  }
}

.site-nav > ul {
  list-style: none;
  padding: 0;
}

.site-nav > ul > li > a {
  display: block;
  margin-bottom: .5rem;
  padding: .75rem 1.75rem;
  text-align: center;
  background-color: #8b2a65;
}

.site-nav > ul > li > a:hover, .site-nav > ul > li > a:focus {
  background-color: #7f265c;
  color: #fff;
}

@media (min-width: 768px) {
  .site-nav > ul {
    display: flex;
    flex-wrap: wrap;
  }
  .site-nav > ul > li > a {
    margin-bottom: 0;
    padding: .25rem 1.25rem;
  }
  .site-nav > ul > li:not(:first-child) > a {
    margin-left: .5rem;
  }
}

.site-footer {
  color: #5b6657;
}

.site-footer > .container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.hero > .container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: calc(100vh - 237px);
  display: flex;
  flex-direction: column;
}

.hero h1 {
  font-size: 1.75rem;
  line-height: 1.1;
  text-align: center;
}

.hero h1 > span {
  font-size: 1rem;
  color: #a43c7b;
}

.hero .hero-image {
  align-self: center;
  margin-top: 2rem;
}

.hero .hero-image > img {
  display: block;
  width: 100%;
  max-width: 375px;
  height: auto;
}

.hero .hero-scroll-icon {
  align-self: center;
  margin-top: auto;
  width: 44px;
  height: 32px;
  animation: up-down 1s infinite alternate;
}

.hero .hero-scroll-icon > img {
  display: block;
  width: 44px;
  height: 32px;
}

@media (min-width: 576px) {
  .hero > .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .hero h1 {
    font-size: 3rem;
  }
  .hero h1 > span {
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero > .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: calc(100vh - 134px);
  }
}

@media (min-width: 992px) {
  .hero h1 {
    max-width: 38rem;
    font-size: 3.5rem;
    text-shadow: 0.0625em 0.0625em #010416;
    text-align: left;
  }
  .hero h1 > span {
    font-size: 1.75rem;
    text-shadow: 0.125em 0.125em #010416;
  }
  .hero .hero-image {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hero > .container {
    padding-top: 12rem;
    padding-bottom: 4rem;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-16px);
  }
}

/*******************
 * Screen-ready only class to hide content visually
 * Solution by Joe Watkins.
 * @see https://zellwk.com/blog/hide-content-accessibly/
 *******************/
.sr-only {
  position: absolute;
  width: 1px;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}
