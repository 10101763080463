/*******************
 * Screen-ready only class to hide content visually
 * Solution by Joe Watkins.
 * @see https://zellwk.com/blog/hide-content-accessibly/
 *******************/
 .sr-only {
  position: absolute;
  width: 1px;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}
