.site-footer {
  // --color-text: red;
  // color: var(--color-text);
  color: #5b6657;
  > .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
  }
}
