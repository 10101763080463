// ==========================================================================
// Base styles and normalization
// ==========================================================================

// ==========================================================================
// #region Document
// ==========================================================================

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// #endregion
// ==========================================================================
// #region Root
// ==========================================================================

:root {
  --bg-color-base: #{$bg-color-base};
  --font-color-base: #{$font-color-base};
  --space: 1.5rem;
  font-size: $font-size-root;
}

// #endregion
// ==========================================================================
// #region Sections
// ==========================================================================

body {
  margin: 0;
  min-height: 100vh;
  background-color: var(--bg-color-base);
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: var(--font-color-base);
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(black, 0);
}

%heading {
  margin: $headings-margin;
  font-family: $headings-font-family;
  font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1 {
  @extend %heading;
  font-size: $h1-font-size;
}

h2 {
  @extend %heading;
  font-size: $h2-font-size;
}

h3 {
  @extend %heading;
  font-size: $h3-font-size;
}

h4 {
  @extend %heading;
  font-size: $h4-font-size;
}

h5 {
  @extend %heading;
  font-size: $h5-font-size;
}

h6 {
  @extend %heading;
  font-size: $h6-font-size;
}

address {
  margin: 0;
  font-style: normal;
  line-height: inherit;
}

// #endregion
// ==========================================================================
// #region Grouping content
// ==========================================================================

p {
  margin: 0;
}

hr {
  margin: 0;
  color: $hr-color;
  background-color: currentColor;
  border: 0;
  opacity: $hr-opacity;
}

hr:not([size]) {
  height: $hr-height;
}

pre {
  display: block;
  margin: 0;
  padding: $pre-padding;
  overflow: auto;
  background-color: $pre-background-color;
  font-family: $font-family-monospace;
  font-size: $code-font-size;
  color: $pre-color;
  tab-size: 2;

  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}

blockquote {
  margin: 0;
}

ol,
ul {
  padding-left: 2rem;
}

ol[class],
ul[class] {
  padding: 0;
}

ol,
ul,
dl {
  margin: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0;
}

dt {
  font-weight: $dt-font-weight;
}

dd {
  margin: 0;
}

figure,
figcaption {
  margin: 0;
}
figcaption {
  padding: $figcaption-padding;
  font-size: $figcaption-font-size;
}

main {
  display: block;
}

// #endregion
// ==========================================================================
// #region Text level semantics
// ==========================================================================

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

a:not([href]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

b,
strong {
  font-weight: $font-weight-bolder;
}

small {
  font-size: $small-font-size;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

code,
kbd,
samp {
  font-family: $font-family-monospace;
  font-size: 1em;
}

code {
  font-size: $code-font-size;
  color: $code-color;
  word-wrap: break-word;

  a > & {
    color: inherit;
  }
}

kbd {
  padding: $kbd-padding-y $kbd-padding-x;
  font-size: $kbd-font-size;
  color: $kbd-color;
  background-color: $kbd-bg;

  kbd {
    padding: 0;
    font-size: 1em;
    font-weight: $nested-kbd-font-weight;
  }
}

sub,
sup {
  position: relative;
  font-size: $sub-sup-font-size;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

mark {
  padding: $mark-padding;
  background-color: $mark-bg;
}

// #endregion
// ==========================================================================
// #region Embedded content
// ==========================================================================

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

// #endregion
// ==========================================================================
// #region Tabular data
// ==========================================================================

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
}

th {
  text-align: inherit;
}

th,
td {
  border: 1px solid #000;
  padding: .5rem;
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $table-caption-color;
  text-align: left;
  caption-side: bottom;
}

// #endregion
// ==========================================================================
// #region Forms
// ==========================================================================

label {
  display: inline-block;
  margin: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  &:not(:disabled) {
    cursor: pointer;
  }
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin: $legend-margin;
  font-size: $legend-font-size;
  font-weight: $legend-font-weight;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

// #endregion
// ==========================================================================
// #region Interactive elements
// ==========================================================================

summary {
  display: list-item;
  cursor: pointer;
}

// #endregion
// ==========================================================================
// #region Scripting
// ==========================================================================

template {
  display: none;
}

// #endregion
// ==========================================================================
// #region Misc.
// ==========================================================================

[hidden] {
  display: none !important;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

// #endregion
// ==========================================================================
// #region Accessibility
// ==========================================================================

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// #endregion
