.site-header {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 120rem;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.site-brand {
  margin-bottom: 2rem;

  > a {
    display: flex;
    align-items: center;
    > span {
      padding-left: 1rem;
    }
  }

  img {
    width: 45px;
  }

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0;
    padding-right: 1.25rem;

    > a {
      > span {
        padding-left: 1.25rem;
        font-size: 1.25rem;
      }
    }

    img {
      width: 90px;
    }
  }
}

.site-nav {
  > ul {
    list-style: none;
    padding: 0;

    > li {
      > a {
        display: block;
        margin-bottom: .5rem;
        padding: .75rem 1.75rem;
        text-align: center;
        background-color: $color-primary;

        &:hover,
        &:focus {
          background-color: darken($color-primary, 3%);
          color: $font-color-base;
        }
      }
    }

    //@media (min-width: $breakpoint-sm) {}

    @media (min-width: $breakpoint-md) {
      display: flex;
      flex-wrap: wrap;
      > li {
        > a {
          margin-bottom: 0;
          padding: .25rem 1.25rem;
        }
        &:not(:first-child) {
          > a {
            margin-left: .5rem;
          }
        }
      }
    }
  }
}
